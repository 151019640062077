export enum RtgAgency {
    Moodys = "Moodys",
    SnP = "SnP",
    Fitch = "Fitch",
    KBRA = "KBRA",
    DBRS = "DBRS",
}

export const RtgAgencyList = [
    { value: RtgAgency.Moodys, text: "Moody’s" },
    { value: RtgAgency.SnP, text: "Standard & Poors" },
    { value: RtgAgency.Fitch, text: "Fitch" },
    { value: RtgAgency.KBRA, text: "KBRA" },
    { value: RtgAgency.DBRS, text: "DBRS" },
];
