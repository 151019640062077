import { roles } from "../constants/roles";
import { accountService } from "../services/account.service";
import { User } from "../types/account/User";
import { Subscription } from "../types/billing/Subscription";
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { jsonUtils } from "../utils/json.utils";
import { getFeatures } from "./features/feature-provider";

type TUserProvider = {
    currentUser: User | null;
    store: (user: User) => void;
    clear: () => void;
    current: () => User | null;
    token: (accessToken?: string) => string | null;
    setBloombergEmail: (bloombergEmail: string) => void;
    setDirectBiddingAgreementSigned: (signed: boolean) => void;
    setPortfolioAgreementSigned: (signed: boolean) => void;
    refreshToken: () => { accessToken: string, refrechToken: string } | {};
    isAuthenticated: () => boolean;
    hasRoles: (...roles: string[]) => boolean;
    hasSingleRole: (role: string) => boolean;
    hasFeatures: (...features: SubscriptionFeature[]) => boolean;
    hasAllFeatures: (...features: SubscriptionFeature[]) => boolean;
    normalizeFeatures: (user: User) => User;
    getOnBoardingTooltipCounters: () => { [tooltipType: string]: number; };
    setOnBoardingTooltipCounter: (tooltipType: string, count: number) => void;
    handleAccountChange: (e: StorageEvent) => void;
    changeSubscription: (
        subscription: Subscription,
        trialPeriodEnd: Date | undefined,
        subscriptionExpiration: Date | undefined
    ) => void;
    [key: string]: any;
}

export const user: TUserProvider = {
    currentUser: null,
    store: function (user: User) {
        const normalized = this.normalizeFeatures(user);
        this.currentUser = normalized;
        localStorage.setItem('user', JSON.stringify(normalized));
        this.watchAccountChange();
    },
    clear: function () {
        localStorage.removeItem('user');
        this.currentUser = null;
        window.removeEventListener('storage', this.handleAccountChange);
    },
    current: function (): User | null {
        if (!this.currentUser) {
            this.currentUser = jsonUtils.tryParse(localStorage.getItem('user'));
            if (this.currentUser) {
                this.watchAccountChange();
            }
        }

        return this.currentUser;
    },
    token: function (accessToken?: string) {
        const currentUser = this.current();

        if (currentUser) {
            if (accessToken) { // set access token if specified
                currentUser.accessToken = accessToken;
                this.store(currentUser);
            }

            return currentUser.accessToken;
        }

        return null;
    },
    setBloombergEmail: function (bloombergEmail: string) {
        const currentUser = this.current();
        if (currentUser) {
            currentUser.bloombergEmail = bloombergEmail;
            this.store(currentUser)
        }
    },
    setDirectBiddingAgreementSigned: function (status: boolean) {
        const currentUser = this.current();
        if (currentUser) {
            currentUser.directBiddingAgreementSigned = status;
            this.store(currentUser)
        }
    },
    refreshToken: function () {
        const currentUser = this.current();
        return !!currentUser ? { accessToken: currentUser.accessToken, refreshToken: currentUser.refreshToken } : {};
    },
    isAuthenticated: function () {
        return !!this.current();
    },
    hasRoles: function (...roles: string[]) {
        const currentUser = this.current();
        return !!currentUser && currentUser.roles && roles.some(r => currentUser.roles.indexOf(r) > -1);
    },
    hasSingleRole: function (role: string) {
        const currentUser = this.current();
        return currentUser?.roles?.length === 1 && this.hasRoles(role);
    },
    hasFeatures: function (...features: SubscriptionFeature[]) {
        if (user.hasRoles(roles.Administrator, roles.SubscriptionManager)) return true;
        const currentUser = this.current();
        return !!currentUser && !!currentUser.features && features.some(f => currentUser.features.indexOf(f) > -1);
    },
    hasAllFeatures: function (...features: SubscriptionFeature[]) {
        if (user.hasRoles(roles.Administrator, roles.SubscriptionManager)) return true;
        const currentUser = this.current();
        return !!currentUser && !!currentUser.features && !features.some(f => currentUser.features.indexOf(f) < 0);
    },
    normalizeFeatures: function (user: User) {
        user.features = getFeatures(user);
        return user;
    },
    getOnBoardingTooltipCounters: function () {
        const currentUser = this.current();
        return (!!currentUser && currentUser.tooltipCounts) || {};
    },
    setOnBoardingTooltipCounter: function (tooltipType, count) {
        const currentUser = this.current();
        if (currentUser) {
            currentUser.tooltipCounts[tooltipType] = count;
            this.store(currentUser);
        }
    },
    changeSubscription: function (
        subscription,
        trialPeriodEnd,
        subscriptionExpiration) {
        const currentUser = this.current();
        if (currentUser) {
            const updatedUser: User = {
                ...currentUser,
                subscription: subscription.title,
                products: subscription.products,
                trialPeriodEnd,
                subscriptionExpiration
            };

            this.store(this.normalizeFeatures(updatedUser));
        }
    },
    watchAccountChange: function () {
        window.addEventListener('storage', this.handleAccountChange);
    },
    handleAccountChange: function (event: StorageEvent) {
        // logout user if account is changed or logout action is performed on another tab
        if (document.hidden && event.storageArea === localStorage && user.currentUser) {
            const storageUser = jsonUtils.tryParse(localStorage.getItem('user'));

            if (!storageUser || (user.currentUser.id !== storageUser.id)) {
                accountService.logout();
            }
        }
    },
    setPortfolioAgreementSigned: function (status: boolean) {
        const currentUser = this.current();
        if(currentUser){
            currentUser.portfolioDisclaimerAgreed = status;
            this.store(currentUser)
        }
    }
};
